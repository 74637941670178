import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./index.css";
import { DataProvider } from './helpers/datacontext';
import React from "react";
import * as Sentry from "@sentry/react";
import App from "./App";
import { allowedDomainUrls } from "./helpers/config";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);


// You can also add other target patterns for additional domains in the same format as above by adding new web and API links separated by commas.

//#region sentry
Sentry.init({
  dsn: "https://342b9648216d13d9cbf5489cb26f1eed@o4505669181767680.ingest.us.sentry.io/4508006855868416",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration()
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: allowedDomainUrls,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: "Stage"
});
root.render(<App />);
//#endregion sentry


root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <DataProvider>
        <App />
      </DataProvider>
    </BrowserRouter>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
